<template>
  <div class="item sozai" :class="{progress: getProgress(), error: item.error === 1, cancel: item.error === 2, attention: needResearch(item)}">
    <div class="column">
      <div class="name" :data-path="item_.pathString">
        <div class="icon"><i class="material-icons">insert_drive_file</i></div>
        <div class="thumb">
          <picture><img v-lazy="{src: `${conf.IMGBASE}${item_.thumbnail_url}`, loading: '/assets/img/pict-loading.png'}"></picture>
        </div>
        <div class="text">{{ item_.source_name ? item_.source_name : item_.gdr_name }}</div>
      </div>
      <div class="error tooltip" data-text="ウォッチリストが更新されています"><i class="material-icons">warning</i></div>
    </div>
    <div class="column">
      <div class="owner">
        <div class="text">{{ item_.owner.replace('@tbs.co.jp', '') }}</div>
      </div>
    </div>
    <div class="column">
      <div class="timecode">
        <div class="text">{{ mixins.getFormatedTimeCode(item_.start_time) }}</div>
      </div>
    </div>
    <div class="column">
      <div class="duration">
        <div class="text">{{ item_.duration }}</div>
      </div>
    </div>
    <div class="column">
      <div class="status" @click.stop="getSourceLog()" @dblclick.stop="">
        <div class="text">{{ item_.str_status + getStep() }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, toRef, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiItem',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['item', 'lastPwatchUpdatedAtMsec'],
  setup(props) {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')

    // このコンポーネントで利用するリアクティブな値
    const folders = ref(store.getters.folders)
    // const item_ = ref(Object.assign({}, props.item))
    const item_ = ref(toRef(props, 'item'))

    return {
      route,
      router,
      store,
      mixins,
      conf,
      folders,
      item_,
    }
  },
  data(): {
    // item_: any
  } {
    return {
      // item_: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    //
  },
  methods: {
    setPath() {
      const parent_folder = this.folders.find((folder: any) => folder.folder_id === this.item_.folder_id)
      this.item_.parent_tbl = parent_folder ? Object.assign([], parent_folder.parent_tbl) : []
      const pathString = this.item_.parent_tbl.map((path: any) => { return path.folder_name }).reverse().join(' > ')
      this.item_.pathString = pathString ? pathString : '/'
    },
    needResearch(item: any) {
      const updated_at_msec: any = new Date(item.updated_at).getTime()
      return Math.sign(this.lastPwatchUpdatedAtMsec - updated_at_msec) >= 0 ? true : false
    },
    getProgress() {
      const target = this.item.str_status
      const query = '完了'
      return !this.mixins.matchRegExp(target, query)
    },
    getStep() {
      switch (this.item.str_status) {
        case 'アップロード中': {
          return '（1/4）'
        }
        case '解析中': {
          return '（2/4）'
        }
        case '解析完了': {
          return '（3/4）'
        }
        default: {
          return ''
        }
      }
    },
    getSourceLog() {
      this.$emit('log')
    },
  },
})
</script>

<style lang="stylus" scoped>
.item.sozai
  display grid
  grid-template minmax(50px, auto) / 1fr 12em 9em 7em 11em
  align-items center
  grid-gap $gap
  padding 0 $gap * 2
  font-size .7rem
  line-height 1.5
  cursor pointer
  & + .item
    border-top 1px solid alpha(#fff, .2)

  .column
    width fit-content
  .name
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    width fit-content
    max-width 100%

    position relative
    .thumb
      aspect-ratio 16/9
      background-color #333
      width 128px

    .text
      ellipsis()

  .error
    display none
  //

  .owner
    .text
      ellipsis()
  //
  .status
    .text
      line-height 1.5
      border-bottom 1px dotted currentColor
  //
  &:hover
    background-color #1C1C1C
  //
  &.progress
    $gc1 = alpha(#fff, .03)
    $gc2 = alpha(#fff, 0)
    background-image linear-gradient(-135deg, $gc1, $gc1 20%, $gc2 20%, $gc2 40%, $gc1 40%, $gc1 60%, $gc2 60%, $gc2 80%, $gc1 80%, $gc1)
    background-size 34px
    background-repeat repeat-x
    animation progress 2s linear infinite
  @keyframes progress
    0%
      background-position 0px
    100%
      background-position 34px
  //
  &.error
    .status .text
      color gold
  //
  &.cancel
    opacity .5

  //
  &.attention
    .column:nth-child(1)
      position relative
    .error
      display flex
      align-items center
      position absolute
      right 100%
      top 0
      height 100%
      margin-right 2px
      color gold
      .material-icons
        font-size 18px
        line-height 1
      &:hover
        tooltip()
  //
  &.on
    background-color $color-active

  .column:nth-of-type(1) .name:hover
    position relative

    &::after
      content attr(data-path)
      display block
      position absolute
      width max-content
      z-index 99
      bottom -1.7em
      left 30px
      padding .1em .5em
      color #000
      background-color alpha(#fff, .9)
      border-radius 2px
      box-shadow 0 0 0 1px #000
      line-height 1.6
      font-size .6rem

//
.lineview
  .item.sozai
    .name
      .icon
        display flex
      .thumb
        display none

.listview
  .item.sozai
    grid-template-rows auto
    .name
      grid-gap 1em
      padding .6em 0
      .icon
        display none
      .thumb
        display block
  .column:nth-of-type(1) .name:hover::after
    bottom .8em !important
    left 140px  !important

</style>
