<template>
  <div class="main finder" v-if="project">
    <!-- 絞り込み -->
    <div class="box-filter">
      <!-- <box-filter></box-filter> -->
      <div class="filter">
        <div class="filter-header">
          <div class="title">
            <span class="icon"><i class="material-icons">search</i></span>
            <span class="text">素材を検索</span>
          </div>
          <div class="memo">プロジェクト内の素材を検索します</div>
        </div>
        <div class="filter-body">
          <div class="input text" :class="{on: sozaiQuery.name}">
            <input type="text" v-model="sozaiQuery.name" placeholder="素材名／フォルダ名から探す">
            <div class="button" @click="sozaiQuery.name = ''; clearSelected()"><i class="material-icons">cancel</i></div>
          </div>
          <div class="input text" :class="{on: sozaiQuery.speech && filtering}">
            <input type="text" v-model="sozaiQuery.speech" placeholder="音声解析から探す" @input="updateQuery" @keydown.enter="onFilter()" @compositionstart="composing = true" @compositionend="composing = false">
            <div class="button" @click="sozaiQuery.speech = ''; resetSource()"><i class="material-icons">cancel</i></div>
          </div>
          <div class="input text" :class="{on: sozaiQuery.cast && filtering}">
            <input
              v-model="sozaiQuery.cast"
              type="list"
              placeholder="登場人物から探す"
              @input="updateQuery"
              @keydown="ui.suggest = true"
              @keydown.enter="onFilter()"
              @keydown.up.prevent="selectSuggest(-1)"
              @keydown.down.prevent="selectSuggest(1)"
              @focus="ui.suggest = true"
              @blur="clearSelectSuggest()"
              @compositionstart="composing = true"
              @compositionend="composing = false"
            />
            <div class="button" @click="sozaiQuery.cast = ''; resetSource()"><i class="material-icons">cancel</i></div>
            <div class="suggests" v-if="castSuggests.length && ui.suggest">
              <div v-for="(item, i) in castSuggests" :key="i" class="item" :class="{on: item.select}" @mousedown.prevent="clickSuggest(item)">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="filter-footer">
          <div class="button" :class="{disabled: !selects.length}" @click="onSearch">
            <span class="icon"><i class="material-icons">person_search</i></span>
            <span class="text">シーンを検索</span>
          </div>
          <div class="memo">選択されている素材の中から検索します</div>
        </div>
      </div>
    </div>
    <!-- リスト -->
    <div class="box-list">
      <!-- リスト上部のUI -->
      <div class="box-header">
        <div class="location">
          <div class="path" @click="clearPath(); refresh()">
            <div class="icon"><i class="material-icons">movie</i></div>
            <div class="text">{{ project.project_name }}</div>
            <div v-if="project.user_type !== 2 || isSuperUser" class="button" @click.stop="openShare"><i class="material-icons">group</i></div>
          </div>
        </div>
        <div class="ui">
          <div class="button" :class="{on: ui.folderView}" @click="ui.folderView = !ui.folderView; clearSelected()" data-text="フォルダ表示"><i class="kaotan-icons folderview"></i></div>
          <div class="button" v-if="ui.viewType === 2" @click="ui.viewType = 1" data-text="リスト表示"><i class="material-icons">view_headline</i></div>
          <div class="button" v-if="ui.viewType === 1" @click="ui.viewType = 2" data-text="サムネイル表示"><i class="material-icons">view_list</i></div>
          <div class="button separate" data-text="ウォッチ&#010;リスト管理"><router-link to="./watchlist/"><i class="material-icons">face</i></router-link></div>
        </div>
      </div>
      <!-- リスト本体 -->
      <div class="box-content" v-if="!isUpdate">
        <div class="content-header">
          <div class="breadcrumbs" v-if="ui.folderView" :class="{on: currentFolder_ && currentFolder_.parent_tbl.length}">
            <div class="icon" @click="clearPath"><i class="material-icons">folder</i></div>
            <template v-if="currentFolder_ && currentFolder_.parent_tbl.length">
              <!-- pathが5つ以上 -->
              <div class="location" v-if="currentFolder_.parent_tbl.length > 4">
                <div class="path"><div class="text" @click.stop="openFolder(currentFolder_.parent_tbl.slice(0)[0])">{{ currentFolder_.parent_tbl.slice(0)[0].folder_name }}</div></div>
                <div class="path"><div class="text">…</div></div>
                <div class="path"><div class="text" @click.stop="openFolder(currentFolder_.parent_tbl.slice(-2)[0])">{{ currentFolder_.parent_tbl.slice(-2)[0].folder_name }}</div></div>
                <div class="path"><div class="text" @click.stop="openFolder(currentFolder_.parent_tbl.slice(-1)[0])">{{ currentFolder_.parent_tbl.slice(-1)[0].folder_name }}</div></div>
              </div>
              <!-- pathが4つ以内 -->
              <div class="location" v-else>
                <div class="path" v-for="(folder, i) in currentFolder_.parent_tbl" :key="i">
                  <div class="text" @click="openFolder(folder)">{{ folder.folder_name }}</div>
                </div>
              </div>
            </template>
          </div>
          <div class="listheadings">
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'name'}" @click="setSort('name')">
                <span class="text">名前</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'owner'}" @click="setSort('owner')">
                <span class="text">作成者</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'start_time'}" @click="setSort('start_time')">
                <span class="text">タイムコード</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'seconds'}" @click="setSort('seconds')">
                <span class="text">素材尺</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'str_status'}" @click="setSort('str_status')">
                <span class="text">ステータス</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
          </div>
        </div>
        <!-- フォルダビュー -->
        <div class="content-body" v-if="ui.folderView">
          <div v-if="currentFolder_.folders.length || currentFolder_.sources.length" class="list folderList" :class="{lineview: ui.viewType === 1, listview: ui.viewType === 2}">
            <!-- 指定パス直下のフォルダ -->
            <div
              class="item folder" v-for="(folder, f) in currentFolder_.folders"
              :key="f"
              :class="{open: folder.open, on: folder.select}"
              @click="selectFolder($event, folder)"
              @dblclick.stop="openFolder(folder)"
              @contextmenu.prevent.stop="openContextMenu($event, folder)"
            >
              <div
                class="item-header"
                :class="{on: folder.slect}"
              >
                <div class="column">
                  <div class="name">
                    <span class="icon" @click.stop="folder.open = !folder.open" @dblclick.stop=""><i class="material-icons">folder</i></span>
                    <span class="text">{{ folder.folder_name }}</span>
                  </div>
                </div>
              </div>
              <div class="item-body">
                <item-sozai
                  v-for="(item, i) in folder.offspring"
                  :key="item.source_id"
                  :item="item"
                  :last-pwatch-updated-at-msec="last_pwatch_updated_at_msec"
                  :class="{on: item.select}"
                  @click.stop="selectSource($event, item, i, folder, true)"
                  @dblclick.stop="onResult($event, item)"
                  @contextmenu.prevent.stop="openContextMenu($event, item, i, folder, true)"
                  @log="openLog(item)"
                ></item-sozai>
              </div>
            </div>
            <!-- 指定パス直下の素材 -->
            <item-sozai
              v-for="(item, i) in currentFolder_.sources"
              :key="item.source_id"
              :item="item"
              :last-pwatch-updated-at-msec="last_pwatch_updated_at_msec"
              :class="{on: item.select}"
              @click="selectSource($event, item, i, currentFolder_, false)"
              @dblclick.stop="onResult($event, item)"
              @contextmenu.prevent.stop="openContextMenu($event, item, i, currentFolder_, false)"
              @log="openLog(item)"
            ></item-sozai>
          </div>
          <div v-else-if="!sources.length" class="empty">
            <p>登録されている素材がありません</p>
          </div>
          <div v-else class="empty">
            <p>検索条件に一致する素材がありません</p>
          </div>
        </div>
        <!-- 素材一覧ビュー -->
        <div class="content-body" v-else>
          <!-- 選択されたプロジェクト内の素材一覧 -->
          <div v-if="sources_.length" class="list sozaiList" :class="{lineview: ui.viewType === 1, listview: ui.viewType === 2}">
            <item-sozai
              v-for="(item, i) in sources_"
              :key="item.source_id"
              :item="item"
              :last-pwatch-updated-at-msec="last_pwatch_updated_at_msec"
              :class="{on: item.select}"
              @click="selectSource($event, item, i, null, null)"
              @dblclick.stop="onResult($event, item)"
              @contextmenu.prevent.stop="openContextMenu($event, item, i, null, null)"
              @log="openLog(item)"
            ></item-sozai>
          </div>
          <div v-else-if="!sources.length" class="empty">
            <p>登録されている素材がありません</p>
          </div>
          <div v-else class="empty">
            <p>検索条件に一致する素材がありません</p>
          </div>
        </div>
        <!-- 追加ボタン、右メニューなど -->
        <div class="ui">
          <div class="count">{{ sources_.length }}件</div>
          <div v-if="project.user_type !== 2 || isSuperUser" class="add" :class="{on: ui.add}">
            <div class="button add" @click.stop="ui.add = !ui.add">
              <span class="icon material-icons">add</span>
              <!-- <span class="text">素材を追加</span> -->
            </div>
            <div class="button sozai" @click="openAdd('source')" data-text="素材を追加">
              <span class="icon material-icons">insert_drive_file</span>
            </div>
            <div class="button folder" @click="openAdd('folder')" data-text="新規フォルダを作成">
              <span class="icon material-icons">folder</span>
            </div>
          </div>
          <div class="context" v-if="context" :style="contextStyle()">
            <div class="row" v-if="selectedSources.length === 1 && selectedFolders.length === 0"><div class="button" @click.stop="onResult($event, context.item)"><i class="material-icons">find_in_page</i><span class="text">解析結果</span></div></div>
            <div v-if="project.user_type !== 2 || isSuperUser" class="row"><div class="button" @click.stop="onResearch"><i class="material-icons">find_replace</i><span class="text">再解析</span></div></div>
            <div v-if="((selectedSources.length === 1 && selectedFolders.length === 0) || (selectedSources.length === 0 && selectedFolders.length === 1)) && (project.user_type !== 2 || isSuperUser)" class="row"><div class="button" @click="openEdit"><i class="material-icons">edit</i><span class="text">名前を変更</span></div></div>
            <div v-if="project.user_type !== 2 || isSuperUser" class="row"><div class="button" @click="openMove"><i class="material-icons">folder</i><span class="text">場所を移動</span></div></div>
            <div class="row"><div class="button" @click="openCopy"><i class="material-icons">content_copy</i><span class="text">コピーを作成</span></div></div>
            <div v-if="project.user_type !== 2 || isSuperUser" class="row"><div class="button" @click="openDelete"><i class="material-icons">delete</i><span class="text">削除</span></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dialog-share v-if="share" :data="share" @close="closeDialog" @update="refresh"></dialog-share>
  <dialogs-sozai v-if="dialog.type === 'source'" :dialog="dialog" @close="closeDialog" @update="refresh"></dialogs-sozai>
  <dialogs-folder v-if="dialog.type === 'folder'" :dialog="dialog" @close="closeDialog" @update="refresh"></dialogs-folder>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import DialogProjectShare from '@/components/dialog/ProjectShare.vue'
import DialogsSozai from '@/components/dialog/DialogsSozai.vue'
import DialogsFolder from '@/components/dialog/DialogsFolder.vue'
// ListItem
import ItemSozai from '@/components/item/Sozai.vue'

export default defineComponent({
  name: 'SozaiFinder',
  components: {
    'dialogs-sozai': DialogsSozai,
    'dialogs-folder': DialogsFolder,
    'dialog-share': DialogProjectShare,
    'item-sozai': ItemSozai,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    // const project = store.getters.project

    // リアクティブな変数を定義
    // let list: any = ref(null)
    // let context: any = ref(null)
    const queries = mixins.getQuery()
    // console.log('queries', queries)

    const project_id: any = route.params.project_id

    const currentProject = {
      project_id: parseInt(project_id),
    }

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      queries,
      currentProject,
      isPowerUser,
      isSuperUser,
      // list,
      // tree,
    }
  },
  data(): {
    project: any
    last_pwatch_updated_at_msec: any
    folders: any[]
    sources: any[]
    list: any[] | null
    tree: any[] | null
    context: any
    // add: any
    share: any
    // edit: any
    // del: any
    // move: any
    // copy: any
    dialog: any
    ui: any
    composing: boolean
    filtering: boolean
    isUpdate: boolean
    names: any
    castSuggests: any
  } {
    return {
      project: null,
      last_pwatch_updated_at_msec: null,
      folders: [],
      sources: [],
      list: null,
      tree: null,
      context: null,
      // add: null,
      share: null,
      // edit: null,
      // del: null,
      // move: null,
      // copy: null,
      dialog: {
        type: null,
        method: null,
        data: null,
      },
      ui: {
        folderView: true,
        viewType: 1,
        currentFolder: null,
        add: false,
        sort: {
          key: 'name', // ソートキー
          ascend: true, // true: 昇順, false: 降順
        },
        lastSelected: null,
        suggest: false,
      },
      composing: false,
      filtering: false,
      isUpdate: false,
      names: [],
      castSuggests: [],
    }
  },
  computed: {
    currentFolder_(): any {
      const currentFolderId = this.ui.currentFolder ? this.ui.currentFolder.folder_id : null
      const currentFolder = this.folders.find((folder: any) => folder.folder_id === currentFolderId)

      // 選択されているフォルダに属するfolderとsourceを抜粋
      let folders: any = this.folders.filter((folder: any) => folder.parent_folder_id === currentFolderId)
      let sources: any = this.sources_.filter((source: any) => source.folder_id === currentFolderId)

      // parent_tbl
      const parent_tbl = currentFolder ? Object.assign([], currentFolder.parent_tbl).reverse() : []

      // 所属フォルダにoffspringを生成
      let offspring: any = []
      const scan: any = (folder: any) => {
        this.sources_.forEach((source: any) => {
          if (source.folder_id === folder.folder_id) {
            offspring.push(source)
          }
        })
        const folders = this.folders.filter((kid: any) => kid.parent_folder_id === folder.folder_id)
        if (folders.length) {
          folders.forEach((kid: any) => {
            scan(kid)
          })
        }
      }
      folders.forEach((folder: any) => {
        offspring = []
        // console.log(folder)
        scan(folder)

        // ソート
        offspring.sort((a: any, b: any) => {
          switch (this.ui.sort.key) {
            case 'name': {
              const a_ = a['source_name'] || a['gdr_name']
              const b_ = b['source_name'] || b['gdr_name']
              if (a_ && b_) {
                return a_.localeCompare(b_, 'ja')
              } else {
                return -1
              }
            }
            case 'seconds': {
              return a[this.ui.sort.key] - b[this.ui.sort.key]
            }
            default: {
              if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
                return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
              } else {
                return -1
              }
            }
          }
        })
        // 昇順・降順
        if (!this.ui.sort.ascend) {
          offspring.reverse()
        }

        folder.offspring = offspring
      })

      // フォルダはここで絞り込み
      // offspringがいれば残す
      // そうでない場合、名称がひっかかれば残す

      // onSearchしたときにoffspringがいないフォルダも残ってる？
      folders = folders.filter((folder: any) => {
        if (folder.offspring.length) {
          // いずれにせよ子素材がいるときはtrue
        } else {
          if (this.filtering) {
            // 音声・人物絞り込み状態でoffspringがいないのはfalse
            return false
          } else {
            // 名称で絞り込まれているか
            if (folder.folder_name && this.sozaiQuery.name) {
              return this.mixins.matchRegExp(folder.folder_name, this.sozaiQuery.name)
            } else {
              return true
            }
          }
        }
        // 何かしら絞り込み条件が入力されている
        if (this.filtering) {
          //
          if (folder.offspring.length) {
            // 子素材ががいればtrue
            return true
          } else {
            if (folder.folder_name && this.sozaiQuery.name) {
              return this.mixins.matchRegExp(folder.folder_name, this.sozaiQuery.name)
            } else {
              return this.filtering
            }
          }
        } else {
          // 絞り込み条件なしの場合は全true
          return true
        }
      })

      // ソート
      // 素材
      // リストビューを考慮し、素材の並び替えはsources_で行う
      // sources.sort((a: any, b: any) => {
      //   switch (this.ui.sort.key) {
      //     case 'name': {
      //       const a_ = a['source_name'] || a['gdr_name']
      //       const b_ = b['source_name'] || b['gdr_name']
      //       return a_.localeCompare(b_, 'ja')
      //     }
      //     case 'seconds': {
      //       return a[this.ui.sort.key] - b[this.ui.sort.key]
      //     }
      //     default: {
      //       return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
      //     }
      //   }
      // })
      // フォルダ
      folders.sort((a: any, b: any) => {
        switch (this.ui.sort.key) {
          case 'name': {
            return a['folder_name'].localeCompare(b['folder_name'], 'ja')
          }
          default: {
            return a['folder_name'].localeCompare(b['folder_name'], 'ja')
          }
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        // sources.reverse()
        if (this.ui.sort.key === 'name') {
          folders.reverse()
        }
      }
      return {
        folder_name: 'root',
        parent_folder_id: null,
        parent_tbl: parent_tbl,
        folders: folders,
        sources: sources,
      }
    },
    sources_(): any {
      let sources: any = JSON.parse(JSON.stringify(this.sources))
      if (sources) {
        sources = sources.filter((sozai: any) => {
          if (sozai.folder_id !== null) {
            // 先祖フォルダをもっている場合はそちらでも挑戦
            if (this.mixins.matchRegExp(sozai.pathString, this.sozaiQuery.name)) {
              return true
            }
          }
          if (sozai.source_name) {
            return this.mixins.matchRegExp(sozai.source_name, this.sozaiQuery.name)
          } else if (sozai.gdr_name) {
            return this.mixins.matchRegExp(sozai.gdr_name, this.sozaiQuery.name)
          } else {
            return false
          }
        })
      }
      // ソート
      sources.sort((a: any, b: any) => {
        switch (this.ui.sort.key) {
          case 'name': {
            const a_ = a['source_name'] || a['gdr_name']
            const b_ = b['source_name'] || b['gdr_name']
            if (a_ && b_) {
              return a_.localeCompare(b_, 'ja')
            } else {
              return -1
            }
          }
          case 'seconds': {
            return a[this.ui.sort.key] - b[this.ui.sort.key]
          }
          case 'start_time': {
            const a_ = this.mixins.getFormatedTimeCode(a.start_time)
            const b_ = this.mixins.getFormatedTimeCode(b.start_time)
            if (a_ && b_) {
              return a_.localeCompare(b_, 'ja')
            } else {
              return -1
            }
          }
          default: {
            if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
              return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
            } else {
              return -1
            }
          }
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        sources.reverse()
      }

      return sources
    },
    sort: {
      get(): any {
        return this.store.getters.sort
      },
      set(query): any {
        this.store.commit('setSort', query)
      },
    },
    sozaiQuery: {
      get(): any {
        return this.store.getters.filtersSozaiQuery
      },
      set(query): any {
        this.store.commit('setFiltersSozaiQuery', query)
      },
    },
    selects(): any {
      const offsprings = Object.assign([], this.store.getters.selectedFolders).map((folder: any) => { return folder.offspring })
      let ss = Object.assign([], this.store.getters.selectedSources) // これがないとsourceがアクティブにならない
      // const selects = this.sources_.filter((source: any) => source.select || sf.some((folder_id: number) => folder_id === source.folder_id))
      offsprings.forEach((offspring: any) => {
        ss = ss.concat(offspring)
      })
      // const selects = ss.concat()
      // console.log(ss)
      return ss
    },
    selectedFolders: {
      get(): any {
        return this.store.getters.selectedFolders
      },
      set(query): any {
        this.store.commit('setSelectedFolders', query)
      },
    },
    selectedSources: {
      get(): any {
        return this.store.getters.selectedSources
      },
      set(query): any {
        this.store.commit('setSelectedSources', query)
      },
    },
  },
  watch: {
    'ui.add': function () {
      if (this.ui.add) {
        window.addEventListener('click', this.closeAddMenu)
      } else {
        window.removeEventListener('click', this.closeAddMenu)
      }
    },
    'ui.currentFolder': function () {
      let path = location.pathname
      if (this.ui.currentFolder) {
        path += `?folderId=${this.ui.currentFolder.folder_id}`
      }
      // queryを書き換え
      history.replaceState(null, '', path)
      // storeにも入れる
      this.store.commit('setFolder', this.ui.currentFolder)
    },
    'sozaiQuery.cast': function (val: any) {
      this.refreshCastSuggest(val)
    },
    sources() {
      this.setPathString()
    },
  },
  async beforeMount() {
    if (!this.currentProject) {
      if (this.queries.projectId) {
        // currentProjectをのっとり
        this.currentProject = {
          project_id: parseInt(this.queries.projectId),
        }
      } else {
        this.router.push('/')
        return
      }
    }
    // currentProjectがいるはず
    await this.refresh()
    if (this.sozaiQuery.cast || this.sozaiQuery.speech) {
      await this.onFilter()
    }
  },
  beforeUnmount() {
    this.clearSelected()
  },
  methods: {
    // コンテキストメニューを開く
    openContextMenu(e: any, item: any, index: any, folder: any, offspring: any) {
      // 自身の選択状態で判定
      if (!item.select) {
        // 選択状態を解除
        this.sources_.forEach((item: any, i: any) => {
          item.select = false
        })
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = true
      this.selectedSources = this.sources_.filter((item: any) => item.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)

      this.context = {
        type: item.source_id ? 'source' : 'folder',
        item: item,
        position: { x: e.clientX, y: e.clientY }
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return false }
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      // e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return true }
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      const wb = window.innerHeight
      // console.log(x, y)
      if (this.selects.length === 1) {
        return { left: `${x}px`, top: `${wb - y > 280 ? y - 10 : wb - 290}px` }
      } else {
        return { left: `${x}px`, top: `${wb - y > 230 ? y - 10 : wb - 240}px` }
      }
    },
    // 素材の追加
    openAdd(type: string) {
      this.dialog = {
        type: type,
        method: 'add',
        data: {
          project_id: this.project.project_id,
          parent_folder_id: this.ui.currentFolder ? this.ui.currentFolder.folder_id : null,
        },
      }
      // this.add = {
      //   path: [this.project.name]
      // }
    },
    // 共有設定
    openShare() {
      this.share = this.project
    },
    // 素材の編集
    openEdit() {
      this.dialog = {
        type: this.context.type,
        method: 'edit',
        data: this.context.item,
      }
      // this.edit = this.context.item
    },
    // 素材の移動
    openMove() {
      this.dialog = {
        type: this.context.type,
        method: 'move',
        data: this.context.item,
      }
      // this.move = this.context.item
    },
    // 素材のコピー
    openCopy() {
      this.dialog = {
        type: this.context.type,
        method: 'copy',
        data: this.context.item,
      }
      // this.copy = this.context.item
    },
    // 素材の削除
    openDelete() {
      this.dialog = {
        type: this.context.type,
        method: 'delete',
        data: this.context.item,
      }
    },
    async openLog(item: any) {
      console.log(item)
      const inputs = [item]
      const log = await this.mixins.getsourcelog(inputs)
      alert(log?.outputs[0].text)
    },
    closeDialog() {
      this.share = null
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
    },
    selectSource(e:any, item: any, index: any, folder: any, offspring: any) {
      // forで回す配列を指定する
      // フォルダビューONの場合：offspring配列 or currentFolderのsources
      // フォルダビューOFFの場合: souces_
      let arr: any
      if (folder) {
        if (offspring) {
          arr = folder.offspring
        } else {
          arr = folder.sources
        }
      } else {
        arr = this.sources_
      }
      // 一つ前に選択された素材との関係でリセットする
      if (folder?.folder_id || this.ui.lastSelected?.folder_id) {
        if (this.ui.lastSelected?.folder_id !== folder?.folder_id) {
          // 前回はoffspringを指定、かつ今回別レイヤーの素材を選択
          // 選択状態を解除
          this.sources_.forEach((item: any, i: any) => {
            item.select = false
          })
          this.ui.lastSelected = null
        }
      }

      // 変数
      let from: any = null
      let count: any = null
      // シフトキーが押されている
      if (e.shiftKey) {
        // 選択状態を解除
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
        // 前クリックがある、選択されているものがある
        if (this.ui.lastSelected !== null && this.selectedSources.length) {
          const diff = index - this.ui.lastSelected.index
          if (Math.sign(diff) > 0) {
            from = this.ui.lastSelected.index
            count = diff
          } else {
            from = index
            count = diff * -1
          }
          for (let i = from; i <= from + count; i++) {
            arr[i].select = true
          }
        } else {
          item.select = !item.select
        }
      } else {
        const select_ = item.select
        // メタキーが押されていない
        if (!e.ctrlKey && !e.metaKey) {
          // 選択状態を解除
          this.sources_.forEach((item: any, i: any) => {
            item.select = false
          })
          this.folders.forEach((item: any, i: any) => {
            item.select = false
          })
        }
        if ((!e.ctrlKey && !e.metaKey) && this.selectedSources.length > 1 && select_) {
          item.select = true
        } else {
          item.select = !select_
        }
      }
      this.ui.lastSelected = {index: index, folder_id: folder?.folder_id || null}
      this.selectedSources = this.sources_.filter((item: any) => item.select)
      if (!this.selectedSources.length) {
        this.ui.lastSelected = null
      }
      document.getSelection()?.empty()

      this.closeContextMenu(null)
      // item.select = !item.select // これをおいただけでは非リアクティブ？
      // this.selectedSources = this.sources_.filter((source: any) => source.select)
    },
    selectFolder(e: any, item: any) {
      const select_ = item.select
      if (!e.ctrlKey && !e.metaKey) {
        // 選択状態を解除
        this.sources_.forEach((source: any) => {
          source.select = false
        })
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = !select_
      item.offspring.forEach((source: any) => {
        source.select = false
      })
      this.selectedSources = this.sources.filter((item: any) => item.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)
      // console.log(this.selectedFolders)
      document.getSelection()?.empty()
    },
    clearSelected() {
      this.sources.forEach((source: any) => {
        source.select = false
      })
      this.folders.forEach((folder: any) => {
        folder.select = false
      })
      this.selectedSources = this.sources.filter((source: any) => source.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)
      this.ui.lastSelected = null
    },
    openFolder(folder: any) {
      // フォルダ移動する際に、選択状態をクリア
      this.clearSelected()
      this.ui.currentFolder = folder
    },
    clearPath() {
      this.ui.currentFolder = null
      this.queries = {}
    },
    closeAddMenu() {
      this.ui.add = false
    },
    // キーワードから探す
    async onFilter() {
      if (this.composing) { return }

      // 人物サジェスト選択
      // input中に上下キーでサジェストを選択した場合のルート
      const selected = this.castSuggests.find((suggest: any) => suggest.select)
      if (selected) {
        // 人物選択のenter
        this.clickSuggest(selected)
        return
      }
      const sources = await this.mixins.searchsource(this.sozaiQuery, this.project.project_id)
      this.sources = sources
      this.filtering = true
      this.ui.suggest = false
    },
    resetSource() {
      this.clearSelected()
      this.sources = Object.assign([], this.project.sources)
      this.filtering = false
    },
    updateQuery(e: any) {
      if (!e.target.value) {
        this.resetSource()
      }
    },
    // 人物サジェストを更新
    refreshCastSuggest(queryString: any) {
      let suggests: any = null
      // const queryString = val
      // 前後の空文字をトリム
      const trim = queryString.replace(/^[\u{20}\u{3000}]+/gu, '')
      if (!trim) {
        this.resetSource()
        this.castSuggests = this.names.map((text: any) => {
          return {
            text: text,
            select: false,
          }
        })
      } else {
        // 文中のスペースをTABに置き換え
        // TAB区切りで配列化
        const queries = trim.replace(/[\u{20}\u{3000}]+/gu, '\t').split('\t')
        // 最後のワードに的をしぼる
        const lastQuery = queries.slice(-1)[0]
        if (lastQuery) {
          // namesから該当するものをフィルタ
          suggests = this.names.filter((target: any) => {
            const query = `^(?=.*${lastQuery}).*$`
            const reg = new RegExp(query, 'gi')
            return target.match(reg)?.length ? true : false
          })
          this.castSuggests = suggests.map((text: any) => {
            return {
              text: text,
              select: false,
            }
          })
        } else {
          this.castSuggests = this.names.map((text: any) => {
            return {
              text: text,
              select: false,
            }
          })
        }
      }
    },
    // サジェスト選択
    selectSuggest(diff: any) {
      if (this.composing || !this.castSuggests.length) { return }
      let selected = this.castSuggests.findIndex((suggest: any) => suggest.select)
      if (selected !== -1) {
        // 選択中のsuggestがある
        // 選択中のものをfalseにする
        this.castSuggests[selected].select = false
        // indexを更新
        selected += diff
        // 超過判定
        if (selected < 0) {
          selected = 0
        } else if (selected > this.castSuggests.length - 1) {
          selected = this.castSuggests.length - 1
        }
        this.castSuggests[selected].select = true
      } else {
        // 選択中のsuggestがない
        // => 先頭を選択中に
        selected = 0
        this.castSuggests[0].select = true
      }
    },
    clickSuggest(item: any) {
      const trim = this.sozaiQuery.cast.replace(/^[\u{20}\u{3000}]+/gu, '')
      let queries = trim.replace(/[\u{20}\u{3000}]+/gu, '\v').split('\v') // 垂直タブに置換してsplit
      // 最後の子を置換して連結したものをcastに代入
      queries.pop() // splitで''が入るのでpop
      // 人物名のスペースを置換（NeoEva著名人対策）
      queries.push(item.text.replace(/[\u{20}\u{3000}]/gu, '\t')) // 著名人名のスペースは水平タブに置換
      this.sozaiQuery.cast = queries.join(' ')
      // サジェストリストを非表示に
      // this.ui.suggest = false
      // searchまでやっちゃう
      this.castSuggests.forEach((suggest: any) => suggest.select = false)
      this.onFilter()
    },
    clearSelectSuggest() {
      this.castSuggests.forEach((suggest: any) => {
        suggest.select = false
      })
      this.ui.suggest = false
    },
    // シーン検索へ
    onSearch(e: any) {
      const alert: any = []
      const selects = this.selects
        .map((source: any) => {
          const queryString = 'アップロード中|解析中'
          // create_ginfos以降は許可
          if (source.str_status === '完了') {
             return {
              source_name: source.source_name,
              gdr_name: source.gdr_name,
              source_id: source.source_id,
            }
          } else if (!this.mixins.matchRegExp(source.str_status, queryString)) {
            alert.push(source.source_name || source.gdr_name)
            return {
              source_name: source.source_name,
              gdr_name: source.gdr_name,
              source_id: source.source_id,
            }
          } else {
            alert.push(source.source_name || source.gdr_name)
            return false
          }
        })
        .filter((source: any) => source)
      if (alert.length) {
        let alertString: any = '処理中の動画が含まれています\n'
        alertString += alert.join('\n')
        if (!confirm(alertString)) {
          return
        }
      }
      // console.log(selects)
      if (selects.length > 2) {
        if (!confirm('解析結果の取得に時間がかかる可能性があります')) {
          return
        }
      } else if (selects.length === 0) {
        return
      }
      const sourceId = selects.map((source: any) => {
        return source.source_id
      })
      // this.store.commit('setSearch', selects)
      let query = `?sourceId=${encodeURIComponent(sourceId.join(','))}`
      if (this.sozaiQuery.speech) query += `&speech=${encodeURIComponent(this.sozaiQuery.speech)}`
      if (this.sozaiQuery.cast) query += `&cast=${encodeURIComponent(this.sozaiQuery.cast)}`
      // this.router.push(`./search/${query}`)
      if (e.altKey) {
        this.router.push(`./search/${query}`)
      } else {
        window.open(`./search/${query}`, '_blank')
      }
    },
    // 単体解析結果へ
    onResult(e: any, item: any) {
      // const queryString = 'search_1n|get_wav|put_wav|speech_to_text|put_speech_to_text|get_conv_video|put_nod_video|get_nod_json|put_nod_json|complete'
      const queryString = 'アップロード中|解析中'
      // create_ginfos以降は許可
      if (this.mixins.matchRegExp(item.str_status, queryString)) {
        alert('処理中の動画です')
        return
      }
      this.store.commit('setResult', item)
      let query = `?sourceId=${encodeURIComponent(item.source_id)}`
      if (this.sozaiQuery.speech) query += `&speech=${encodeURIComponent(this.sozaiQuery.speech)}`
      if (this.sozaiQuery.cast) query += `&cast=${encodeURIComponent(this.sozaiQuery.cast)}`
      // this.router.push(`./result/${query}`)
      if (e.altKey) {
        this.router.push(`./result/${query}`)
      } else {
        window.open(`./result/${query}`, '_blank')
      }
    },
    // 再解析
    async onResearch() {
      const res = await this.mixins.research(this.selects)
      if (res) {
        this.refresh()
      } else {
        console.log('エラー')
      }
    },
    // ソート指定
    setSort(key: string) {
      if (key === this.ui.sort.key) {
        this.ui.sort.ascend = !this.ui.sort.ascend
      } else {
        this.ui.sort.key = key
        this.ui.sort.ascend = true
      }
      this.sort = this.ui.sort
    },
    // 検索用のパスを生成
    setPathString() {
      this.sources.forEach((source: any) => {
        // 検索用にここでpathを生成
        const parent_folder = this.folders.find((folder: any) => folder.folder_id === source.folder_id)
        source.parent_tbl = parent_folder ? Object.assign([], parent_folder.parent_tbl) : []
        const pathString = source.parent_tbl.map((path: any) => { return path.folder_name }).reverse().join(' > ')
        source.pathString = pathString ? pathString : '/'
      })
    },
    async refresh() {
      // プロジェクト詳細を取得しviewで必要な値をセット
      this.project = await this.mixins.getProject(this.currentProject)
      this.last_pwatch_updated_at_msec = new Date(this.project.last_pwatch_updated_at).getTime() || 0

      // null check
      if (!this.project) {
        console.log('projectが取得できない')
        this.$router.push('/')
      } else {
        document.title = `${this.project.project_name}｜KAOTAN`

        // 改めてセットする
        this.store.commit('setProject', this.project)

        this.store.commit('setFolders', this.project.folders)

        // 部分更新できるように分離
        this.folders = Object.assign([], this.project.folders)
        this.sources = Object.assign([], this.project.sources)

        // 初期値
        // query指定されたfolderが存在してたら入れる
        let currentFolder: any = null
        if (this.queries.folderId) {
          currentFolder = this.project.folders.find((folder: any) => folder.folder_id === parseInt(this.queries.folderId))
        } else if (this.store.getters.folder) {
          currentFolder = this.store.getters.folder
        }
        this.ui.currentFolder = currentFolder

        // 人物サジェストリストを作成
        const names: any = []
        this.sources.forEach((source: any) => {
          if (source.names) {
            source.names.split(',').forEach((name: any) => {
              if (names.every((n: any) => n !== name)) {
                names.push(name)
              }
            })
          }
        })
        this.names = names
        this.refreshCastSuggest(this.sozaiQuery.cast)

        // ダイアログもリセット
        this.closeDialog()
      }
    },
  },
})
</script>

<style lang="stylus" scoped>
.content-header
  display grid
  grid-template auto / auto
  grid-gap 2px
  .breadcrumbs
    display grid
    grid-template 50px / auto 1fr
    grid-gap .5em
    align-items center
    background-color $color-base--light
    padding 0 $gap
    font-size .7rem
    color #666
    &.on
      color #fff
    .icon
      cursor pointer
      &:hover
        color $color-key1
    .location
      display grid
      grid-template auto \/ repeat(4, auto)
      grid-gap 2rem
      align-items center
      justify-content flex-start
      .path
        display grid
        grid-template auto / auto
        // grid-auto-flow column
        // grid-gap .8em
        // align-items center
        cursor pointer
        &:hover
          color $color-key1
        & + .path
          position relative
          &::before
            beforeIcon('\e5cc')
            position absolute
            display block

            right 100%
            top 0
            width 2rem
            line-height 1
            pointer-events none
            cursor auto
            color #fff
            text-align center

  //
  .listheadings
    display grid
    grid-template minmax(40px, auto) / 1fr 12em 9em 7em 11em
    align-items center
    grid-gap $gap
    padding 0 $gap * 2
    font-size .7rem
    border-bottom 1px solid alpha(#fff, .2)
    .column
      .key
        display grid
        grid-template auto \/ auto auto
        grid-gap 4px
        align-items center
        justify-content flex-start
        width fit-content
        cursor pointer
        .text
          ellipsis()
        .icon
          display none
          i
            font-size 20px
        &.on
          .icon
            display inline-flex

//
.content-body
  height 100%
  overflow auto
//
.list:not(.box) // class名はあとで考える
  font-size .7rem
  margin-bottom 60px
  &:last-child
    border-bottom 1px solid alpha(#fff, .2)

  &.folderList
    .item
      &.folder
        cursor pointer
        & + .item
          .item-header
            border-top 1px solid alpha(#fff, .2)
        .item-header
          // background-color #2E2E2E
          display grid
          grid-template minmax(50px, auto) / auto
          grid-gap 1em
          align-items center
          padding 0 $gap * 2

          .column
            width fit-content

          .name
            display grid
            grid-template minmax(50px, auto) / auto 1fr
            align-items center
            grid-gap .5em
            width fit-content
            .icon
              cursor pointer
              position relative
              user-select none
              &::before
                beforeIcon('\e5df')
                position absolute
                right 100%
              &:hover
                color $color-key1
            .text
              ellipsis()
              // &:hover
              //   color $color-key1
          &:hover
            background-color #1C1C1C

        .item-body
          display none
          .item.sozai
            padding-left $gap * 3.5
          .item.sozai:first-child
            border-top 1px solid alpha(#fff, .2)

        &.open
          // background-color #2E2E2E
          .item-header
            .name .icon::before
              content '\e5c5'
          .item-body
            display block
        &.on
          background-color $color-active
          .item-header
            background-color transparent
            .text
              pointer-events none
          .item.sozai
            background-color transparent
            pointer-events none
      & + .item.sozai
        border-top 1px solid alpha(#fff, .2)


  //
  &.sozaiList
    .item
      // .listheadingsとのレイアウトの揃えはここで書いてもよいかも
  //
</style>
