<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">場所を移動</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <!-- 追加する場所 -->
        <div class="location">
          <div class="button" v-if="current.folder" @click="back"><span class="icon"><i class="material-icons">arrow_back</i></span></div>
          <div class="path" v-if="!current.folder"><span class="icon"><i class="material-icons">movie</i></span><span class="text">{{ current.project.project_name }}</span></div>
          <div class="path" v-else><span class="icon"><i class="material-icons">folder</i></span><span class="text">{{ current.folder.folder_name }}</span></div>
        </div>
        <div class="list">
          <div class="item" v-for="(folder, i) in list" :class="{on: folder.selected}" :key="i" @click="selectFolder(i)" @dblclick="openFolder(i)">
            <div class="path">
              <span class="icon"><i class="material-icons">folder</i></span>
              <span class="text">{{ folder.folder_name }}</span>
              <span class="icon"><i class="material-icons">chevron_right</i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" :class="{disabled: !direction}" @click="update">移動</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiMove',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    let selectedFolders = Object.assign([], store.getters.selectedFolders)
    let selectedSources = Object.assign([], store.getters.selectedSources)
    return {
      store,
      mixins,
      selectedFolders,
      selectedSources,
    }
  },
  // setup(props) {
  //   const store = useStore()
  //   const mixins = new Mixins()
  //   let selects = Object.assign([], store.getters.selectedSources)
  //   if (!selects.length) {
  //     selects = [props.data]
  //   }
  //   return {
  //     store,
  //     mixins,
  //     selects,
  //   }
  // },
  data(): {
    editable: any
    current: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    list(): any {
      if (!this.current.folder) {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === null)
      } else {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === this.current.folder.folder_id)
      }
    },
    direction(): any {
      const selection = this.list.find((folder: any) => folder.selected === true)
      let direction: any
      if (selection) {
        direction = {
          project_id: this.current.project.project_id,
          folder_id: selection.folder_id,
        }
      } else {
        if (this.current.folder) {
          direction = {
            project_id: this.current.project.project_id,
            folder_id: this.current.folder.folder_id,
          }
        } else {
          direction = {
            project_id: this.current.project.project_id,
            folder_id: null,
          }
        }
      }

      // 自分が元いた場所はfalse判定をいれる
      // 無限ループ禁止処理
      const directionFolder = this.current.project.folders.find((folder: any) => folder.folder_id === direction.folder_id)
      if (this.selectedFolders.length) {
        const check = this.selectedFolders.every((folder: any) => {
          if (directionFolder && directionFolder.parent_tbl.length) {
            return directionFolder.parent_tbl.every((parent: any) => parent.folder_id !== folder.folder_id)
          } else {
            return folder.parent_folder_id !== direction.folder_id
          }
        })
        if (check) {
          return direction
        } else {
          return false
        }
      }
      // 選択された素材の親が同じ場合がある
      if (this.selectedSources.length) {
        const check = this.selectedSources.every((source: any) => source.folder_id !== direction.folder_id)
        if (check) {
          return direction
        } else {
          return false
        }
      }
      return direction
      // if (this.data.folder_id === direction.folder_id) {
      //   return false
      // } else {
      //   return direction
      // }
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    async update() {
      let resfolder: any = true
      let ressource: any = true
      if (this.selectedFolders.length) {
        resfolder = await await this.mixins.movefolder({
          direction: this.direction,
          selects: this.selectedFolders,
        })
      }
      if (this.selectedSources.length) {
        ressource = await await this.mixins.movesource({
          direction: this.direction,
          selects: this.selectedSources,
        })
      }
      if (resfolder && ressource) {
        this.$emit('update')
        this.store.commit('clearSelects')
      } else {
        console.log('エラー')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder.parent_folder_id) {
        this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
      } else {
        this.current.folder = null
      }
    },
    selectFolder(i: number) {
      if (this.list[i].selected) {
        this.list[i].selected = false
      } else {
        this.current.project.folders.forEach((folder: any) => { folder.selected = false })
        this.list[i].selected = true
      }
    },
    openFolder(i: number) {
      this.current.project.folders.forEach((folder: any) => { folder.selected = false })
      this.current.folder = this.list[i]
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

.location
  display grid
  grid-template auto \/ 24px 1fr
  align-items center
  grid-gap $gap * .5
  & + .list
    margin-top $gap * .5
  .path
    grid-column 2
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    .text
      ellipsis()

.list
  height 250px
  border-top 1px solid #bababa
  border-bottom 1px solid #bababa
  background-color #F7F7F7
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em $gap
    padding-left calc(24px + .5rem)
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3
    cursor pointer
    user-select none

    &.on
      background-color $color-active
      color #fff

    .path
      display grid
      grid-template auto / auto 1fr auto
      grid-gap .5em
      align-items center
      .text
        ellipsis()
//
</style>
