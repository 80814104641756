<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">コピーを作成</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <!-- 追加する場所 -->
        <div class="location">
          <div class="button" v-if="current.project || current.folder" @click="back"><span class="icon"><i class="material-icons">arrow_back</i></span></div>
          <div class="path" v-if="!current.project"><span class="icon"><i class="material-icons">movie</i></span><span class="text">マイプロジェクト</span></div>
          <div class="path" v-else-if="!current.folder"><span class="icon"><i class="material-icons">movie</i></span><span class="text">{{ current.project.project_name }}</span></div>
          <div class="path" v-else><span class="icon"><i class="material-icons">folder</i></span><span class="text">{{ current.folder.folder_name }}</span></div>
        </div>
        <div class="list">
          <template v-if="!current.project">
            <div class="item" v-for="(project, i) in projects" :class="{on: project.selected}" :key="i" @click="selectProject(i)" @dblclick="openProject(i)">
              <div class="path">
                <span class="icon"><i class="material-icons">movie</i></span>
                <span class="text">{{ project.project_name }}</span>
                <span class="icon"><i class="material-icons">chevron_right</i></span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="item" v-for="(folder, i) in list" :class="{on: folder.selected}" :key="i" @click="selectFolder(i)" @dblclick="openFolder(i)">
              <div class="path">
                <span class="icon"><i class="material-icons">folder</i></span>
                <span class="text">{{ folder.folder_name }}</span>
                <span class="icon"><i class="material-icons">chevron_right</i></span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" :class="{disabled: !direction}" @click="update">複製</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiCopy',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    let selectedFolders = Object.assign([], store.getters.selectedFolders)
    let selectedSources = Object.assign([], store.getters.selectedSources)
    return {
      store,
      mixins,
      selectedFolders,
      selectedSources,
    }
  },
  data(): {
    editable: any
    projects: any
    current: any
  } {
    return {
      editable: null,
      projects: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    list(): any {
      if (!this.current.folder) {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === null)
      } else {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === this.current.folder.folder_id)
      }
    },
    direction(): any {
      if (!this.projects) { return null }

      if (this.current.project) {
        if (this.current.folder) {
          return {
            project_id: this.current.project.project_id,
            folder_id: this.current.folder.folder_id,
          }
        } else {
          const selection = this.current.project.folders.find((folder: any) => folder.selected === true)
          if (selection) {
            return {
              project_id: this.current.project.project_id,
              folder_id: selection.folder_id,
            }
          } else {
            return {
              project_id: this.current.project.project_id,
              folder_id: null,
            }
          }
        }
      } else {
        const selectedProject = this.projects.find((project: any) => project.selected === true)
        if (selectedProject) {
          return {
            project_id: selectedProject.project_id,
            folder_id: null,
          }
        } else {
          return null
        }
      }
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    const projects = await this.mixins.getListProject()
    this.projects = projects.filter((project: any) => project.project_id !== this.data.project_id && project.project_name && project.user_type !== 2)
  },
  methods: {
    async update() {
      let resfolder: any = true
      let ressource: any = true
      if (this.selectedFolders.length) {
        resfolder = await await this.mixins.copyfolder({
          direction: this.direction,
          selects: this.selectedFolders,
        })
      }
      if (this.selectedSources.length) {
        ressource = await await this.mixins.copysource({
          direction: this.direction,
          selects: this.selectedSources,
        })
      }
      if (resfolder && ressource) {
        this.$emit('update')
        this.store.commit('clearSelects')
      } else {
        console.log('エラー')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder) {
        if (this.current.folder.parent_folder_id) {
          this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
        } else {
          this.current.folder = null
        }
      } else {
        this.current.project = null
      }
    },
    selectProject(i: number) {
      if (this.projects[i].selected) {
        this.projects[i].selected = false
      } else {
        this.projects.forEach((project: any) => { project.selected = false })
        this.projects[i].selected = true
      }
    },
    async openProject(i: number) {
      this.current.project = await this.mixins.getProject(this.projects[i])
    },
    selectFolder(i: number) {
      if (this.list[i].selected) {
        this.list[i].selected = false
      } else {
        this.current.project.folders.forEach((folder: any) => { folder.selected = false })
        this.list[i].selected = true
      }
    },
    openFolder(i: number) {
      this.current.project.folders.forEach((folder: any) => { folder.selected = false })
      this.current.folder = this.list[i]
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

.location
  display grid
  grid-template auto / 24px 1fr
  align-items center
  grid-gap $gap * .5
  & + .list
    margin-top $gap * .5
  .path
    grid-column 2
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    .text
      ellipsis()

.list
  height 250px
  border-top 1px solid #bababa
  border-bottom 1px solid #bababa
  background-color #F7F7F7
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em $gap
    padding-left calc(24px + .5rem)
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3
    cursor pointer
    user-select none

    &.on
      background-color $color-active
      color #fff

    .path
      display grid
      grid-template auto / auto 1fr auto
      grid-gap .5em
      align-items center
      .text
        ellipsis()

//


</style>
