
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiMove',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    let selectedFolders = Object.assign([], store.getters.selectedFolders)
    let selectedSources = Object.assign([], store.getters.selectedSources)
    return {
      store,
      mixins,
      selectedFolders,
      selectedSources,
    }
  },
  // setup(props) {
  //   const store = useStore()
  //   const mixins = new Mixins()
  //   let selects = Object.assign([], store.getters.selectedSources)
  //   if (!selects.length) {
  //     selects = [props.data]
  //   }
  //   return {
  //     store,
  //     mixins,
  //     selects,
  //   }
  // },
  data(): {
    editable: any
    current: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    list(): any {
      if (!this.current.folder) {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === null)
      } else {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === this.current.folder.folder_id)
      }
    },
    direction(): any {
      const selection = this.list.find((folder: any) => folder.selected === true)
      let direction: any
      if (selection) {
        direction = {
          project_id: this.current.project.project_id,
          folder_id: selection.folder_id,
        }
      } else {
        if (this.current.folder) {
          direction = {
            project_id: this.current.project.project_id,
            folder_id: this.current.folder.folder_id,
          }
        } else {
          direction = {
            project_id: this.current.project.project_id,
            folder_id: null,
          }
        }
      }

      // 自分が元いた場所はfalse判定をいれる
      // 無限ループ禁止処理
      const directionFolder = this.current.project.folders.find((folder: any) => folder.folder_id === direction.folder_id)
      if (this.selectedFolders.length) {
        const check = this.selectedFolders.every((folder: any) => {
          if (directionFolder && directionFolder.parent_tbl.length) {
            return directionFolder.parent_tbl.every((parent: any) => parent.folder_id !== folder.folder_id)
          } else {
            return folder.parent_folder_id !== direction.folder_id
          }
        })
        if (check) {
          return direction
        } else {
          return false
        }
      }
      // 選択された素材の親が同じ場合がある
      if (this.selectedSources.length) {
        const check = this.selectedSources.every((source: any) => source.folder_id !== direction.folder_id)
        if (check) {
          return direction
        } else {
          return false
        }
      }
      return direction
      // if (this.data.folder_id === direction.folder_id) {
      //   return false
      // } else {
      //   return direction
      // }
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    async update() {
      let resfolder: any = true
      let ressource: any = true
      if (this.selectedFolders.length) {
        resfolder = await await this.mixins.movefolder({
          direction: this.direction,
          selects: this.selectedFolders,
        })
      }
      if (this.selectedSources.length) {
        ressource = await await this.mixins.movesource({
          direction: this.direction,
          selects: this.selectedSources,
        })
      }
      if (resfolder && ressource) {
        this.$emit('update')
        this.store.commit('clearSelects')
      } else {
        console.log('エラー')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder.parent_folder_id) {
        this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
      } else {
        this.current.folder = null
      }
    },
    selectFolder(i: number) {
      if (this.list[i].selected) {
        this.list[i].selected = false
      } else {
        this.current.project.folders.forEach((folder: any) => { folder.selected = false })
        this.list[i].selected = true
      }
    },
    openFolder(i: number) {
      this.current.project.folders.forEach((folder: any) => { folder.selected = false })
      this.current.folder = this.list[i]
    },
  },
})
