
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    return {
      route,
      router,
      store,
      mixins,
    }
  },
  data(): {
    editable: any
    current: any
    files: any
    caution: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
      files: [],
      caution: false,
    }
  },
  computed: {
    // project(): any {
    //   return this.store.getters.project
    // },
    driveFiles(): any {
      return this.store.getters.driveFiles
    },
    deviceFiles(): any {
      if (this.files.length) {
        return Array.from(this.files).map((file: any) => {
          return {
            file_name: file.name,
            source_name: file.name,
          }
        })
      } else {
        return []
      }
    },
    filesCount(): any {
      if (this.driveFiles.length) {
        const files = this.driveFiles.filter((file: any) => {
          const target = file.mimeType
          const query = 'folder'
          return !this.mixins.matchRegExp(target, query)
        })
        if (files.length) {
          return files.length
        } else {
          return 0
        }
      } else if (this.deviceFiles.length) {
        return this.deviceFiles.length
      } else {
        return null
      }
    }
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    countConfirm() {
      if (this.filesCount >= 30) {
        this.caution = true
      } else {
        this.add()
      }
    },
    reconfirm() {
      if (confirm('30件以上の動画を解析しますか？（かおたん担当の許可が必要です。）')) {
        this.add()
      }
    },
    async add() {
      if (this.driveFiles.length) {
        const inputs = JSON.parse(JSON.stringify(this.driveFiles))
        inputs.forEach((file: any) => {
          file.project_id = this.data.project_id
          file.folder_id = this.data.parent_folder_id
        })
        const res = await this.mixins.addSources(inputs)
        this.store.commit('setDriveFiles', [])
        if (res) {
          this.$emit('update')
        } else {
          console.log('error')
        }
      } else if (this.deviceFiles.length) {
        // // readerを利用する場合
        // const readFile = (file: any) => {
        //   return new Promise((resolve, reject) => {
        //     const reader = new FileReader()
        //     reader.onload = () => {
        //       resolve(reader.result)
        //     }
        //     reader.readAsDataURL(file)
        //   })
        // }
        // // 同期処理
        // readFile(file).then((res: any) => {
        //   console.log(res)
        // })
        const inputs = this.deviceFiles.map((file: any) => {
          return {
            filename: file.file_name,
            source_name: file.source_name,
          }
        })
        const res = await this.mixins.deviceupload(inputs, this.files, this.data)
        this.files = []
        if (res) {
          this.$emit('update')
        } else {
          console.log('error')
        }
      }
    },
    picker() {
      this.files = []
      this.mixins.pickerapi()
    },
    change(e: any) {
      const regexp = new RegExp(/video\/mp4|video\/quicktime|video\/avi|application\/octet-stream|video\/mp2t|text\/texmacs|video\/x-ms-wmv/, 'g')
      this.store.commit('setDriveFiles', [])
      this.files = Array.from(e.target.files).filter((file: any) => file.type.match(regexp))
    },
    close() {
      this.store.commit('setDriveFiles', [])
      this.files = []
      this.$emit('close')
    },
  },
})
