<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">名前を変更</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <div class="input text"><input type="text" v-model="editable.folder_name" placeholder="フォルダ名を入力してください"></div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="update">変更</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'FolderEdit',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const mixins = new Mixins()
    return {
      mixins,
    }
  },
  data() {
    return {
      editable: null,
    }
  },
  computed: {
    validation() {
      let error = ''
      if (!this.editable.folder_name) {
        error += 'フォルダ名が未入力です'
      }
      return error
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = Object.assign({}, this.data)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async update() {
      if (this.validation) {
        alert(this.validation)
        return
      }
      const res = await this.mixins.updatefolder(this.editable)
      if (res) {
        this.$emit('update')
      }
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 540px
    height auto

.input
  &.text
    background-color #F4F4F4
    border-bottom 1px solid #BABABA
    padding 0 .5em
    input
      display block
      width 100%
      line-height 50px
      &::placeholder
        color #aaa
</style>
