
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'FolderAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    return {
      store,
      mixins,
    }
  },
  data(): {
    editable: any
    current: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    validation() {
      let error = ''
      if (!this.editable.folder_name) {
        error += 'フォルダ名が未入力です'
      }
      return error
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.editable.folder_name = ''
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async add() {
      if (this.validation) {
        alert(this.validation)
        return
      }
      const res = await this.mixins.createfolder(this.editable)
      if (res) {
        this.$emit('update')
      }
    },
  },
})
