
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiCopy',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    let selectedFolders = Object.assign([], store.getters.selectedFolders)
    let selectedSources = Object.assign([], store.getters.selectedSources)
    return {
      store,
      mixins,
      selectedFolders,
      selectedSources,
    }
  },
  data(): {
    editable: any
    projects: any
    current: any
  } {
    return {
      editable: null,
      projects: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    list(): any {
      if (!this.current.folder) {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === null)
      } else {
        return this.current.project.folders.filter((folder: any) => folder.parent_folder_id === this.current.folder.folder_id)
      }
    },
    direction(): any {
      if (!this.projects) { return null }

      if (this.current.project) {
        if (this.current.folder) {
          return {
            project_id: this.current.project.project_id,
            folder_id: this.current.folder.folder_id,
          }
        } else {
          const selection = this.current.project.folders.find((folder: any) => folder.selected === true)
          if (selection) {
            return {
              project_id: this.current.project.project_id,
              folder_id: selection.folder_id,
            }
          } else {
            return {
              project_id: this.current.project.project_id,
              folder_id: null,
            }
          }
        }
      } else {
        const selectedProject = this.projects.find((project: any) => project.selected === true)
        if (selectedProject) {
          return {
            project_id: selectedProject.project_id,
            folder_id: null,
          }
        } else {
          return null
        }
      }
    },
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
    const projects = await this.mixins.getListProject()
    this.projects = projects.filter((project: any) => project.project_id !== this.data.project_id && project.project_name && project.user_type !== 2)
  },
  methods: {
    async update() {
      let resfolder: any = true
      let ressource: any = true
      if (this.selectedFolders.length) {
        resfolder = await await this.mixins.copyfolder({
          direction: this.direction,
          selects: this.selectedFolders,
        })
      }
      if (this.selectedSources.length) {
        ressource = await await this.mixins.copysource({
          direction: this.direction,
          selects: this.selectedSources,
        })
      }
      if (resfolder && ressource) {
        this.$emit('update')
        this.store.commit('clearSelects')
      } else {
        console.log('エラー')
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      if (this.current.folder) {
        if (this.current.folder.parent_folder_id) {
          this.current.folder = this.current.project.folders.find((folder: any) => folder.folder_id === this.current.folder.parent_folder_id)
        } else {
          this.current.folder = null
        }
      } else {
        this.current.project = null
      }
    },
    selectProject(i: number) {
      if (this.projects[i].selected) {
        this.projects[i].selected = false
      } else {
        this.projects.forEach((project: any) => { project.selected = false })
        this.projects[i].selected = true
      }
    },
    async openProject(i: number) {
      this.current.project = await this.mixins.getProject(this.projects[i])
    },
    selectFolder(i: number) {
      if (this.list[i].selected) {
        this.list[i].selected = false
      } else {
        this.current.project.folders.forEach((folder: any) => { folder.selected = false })
        this.list[i].selected = true
      }
    },
    openFolder(i: number) {
      this.current.project.folders.forEach((folder: any) => { folder.selected = false })
      this.current.folder = this.list[i]
    },
  },
})
