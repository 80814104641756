
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiDelete',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    let selectedFolders = Object.assign([], store.getters.selectedFolders)
    let selectedSources = Object.assign([], store.getters.selectedSources)
    return {
      store,
      mixins,
      selectedFolders,
      selectedSources,
    }
  },
  data(): {
    //
  } {
    return {
      // editable: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    // this.editable = Object.assign({}, this.data)
  },
  methods: {
    async update() {
      let resfolder: any = true
      let ressource: any = true
      if (this.selectedFolders.length) {
        resfolder = await this.mixins.deletefolder(this.selectedFolders)
      }
      if (this.selectedSources.length) {
        ressource = await this.mixins.deletesource(this.selectedSources)
      }
      if (resfolder && ressource) {
        this.$emit('update')
        this.store.commit('clearSelects')
      } else {
        console.log('エラー')
      }
    },
    close() {
      this.$emit('close')
    },
  },
})
