
import { defineComponent, inject, toRef, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiItem',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['item', 'lastPwatchUpdatedAtMsec'],
  setup(props) {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')

    // このコンポーネントで利用するリアクティブな値
    const folders = ref(store.getters.folders)
    // const item_ = ref(Object.assign({}, props.item))
    const item_ = ref(toRef(props, 'item'))

    return {
      route,
      router,
      store,
      mixins,
      conf,
      folders,
      item_,
    }
  },
  data(): {
    // item_: any
  } {
    return {
      // item_: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    //
  },
  methods: {
    setPath() {
      const parent_folder = this.folders.find((folder: any) => folder.folder_id === this.item_.folder_id)
      this.item_.parent_tbl = parent_folder ? Object.assign([], parent_folder.parent_tbl) : []
      const pathString = this.item_.parent_tbl.map((path: any) => { return path.folder_name }).reverse().join(' > ')
      this.item_.pathString = pathString ? pathString : '/'
    },
    needResearch(item: any) {
      const updated_at_msec: any = new Date(item.updated_at).getTime()
      return Math.sign(this.lastPwatchUpdatedAtMsec - updated_at_msec) >= 0 ? true : false
    },
    getProgress() {
      const target = this.item.str_status
      const query = '完了'
      return !this.mixins.matchRegExp(target, query)
    },
    getStep() {
      switch (this.item.str_status) {
        case 'アップロード中': {
          return '（1/4）'
        }
        case '解析中': {
          return '（2/4）'
        }
        case '解析完了': {
          return '（3/4）'
        }
        default: {
          return ''
        }
      }
    },
    getSourceLog() {
      this.$emit('log')
    },
  },
})
