<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper" v-if="!caution">
      <div class="dialog-header">
        <p class="title">素材を追加</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <!-- 追加する場所 -->
        <div class="location">
          <div class="label">追加する場所：</div>
          <div class="path" v-if="!current.folder"><span class="icon"><i class="material-icons">movie</i></span><span class="text">{{ current.project.project_name }}</span></div>
          <div class="path" v-else><span class="icon"><i class="material-icons">folder</i></span><span class="text">{{ current.folder.folder_name }}</span></div>
        </div>
        <!-- どこから？ -->
        <div class="ui">
          <div class="button" @click="picker" :class="{disabled: deviceFiles.length}">
            <span class="icon"><i class="material-icons">add_to_drive</i></span>
            <span class="text">ドライブから</span>
          </div>
          <div class="button" :class="{disabled: driveFiles.length}">
            <label>
              <span class="icon"><i class="material-icons">upload_file</i></span>
              <span class="text">デバイスから</span>
              <input id="deviceFiles" type="file" multiple @change="change">
            </label>
          </div>
        </div>
        <div class="list">
          <template v-if="driveFiles.length">
            <div>
              <div v-for="(file, i) in driveFiles" :key="i" class="item">
                <div class="name">{{ file.file_name }}</div>
              </div>
            </div>
          </template>
          <template v-else-if="deviceFiles.length">
            <div>
              <div v-for="(file, i) in deviceFiles" :key="i" class="item">
                <div class="name">{{ file.file_name }}</div>
              </div>
            </div>
          </template>
          <div v-else class="empty">ファイルを選択してください</div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="memo"><span v-if="filesCount !== null">{{ `動画素材：${filesCount}件` }}</span></div>
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="countConfirm" :class="{disabled: !driveFiles.length && !deviceFiles.length}">追加</div>
      </div>
    </div>
    <!-- 制限確認 -->
    <div class="caution" v-else>
      <div class="dialog-header">
        <p class="title">ご確認</p>
        <div class="button" @click="caution = false"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-body">
        <p>30件以上の動画が選択されました。<br>30時間以上の動画を解析する場合は<a href="https://sites.google.com/tbs.co.jp/kaotan/home" target="_blank" rel="noopener">問い合わせフォーム</a>より<br>事前にICT局かおたん担当に許可を取ってください。</p>
      </div>
      <div class="dialog-footer">
        <div class="memo"></div>
        <div class="button cancel" @click="caution = false">キャンセル</div>
        <div class="button" @click="reconfirm">追加</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'SozaiAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    return {
      route,
      router,
      store,
      mixins,
    }
  },
  data(): {
    editable: any
    current: any
    files: any
    caution: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
      files: [],
      caution: false,
    }
  },
  computed: {
    // project(): any {
    //   return this.store.getters.project
    // },
    driveFiles(): any {
      return this.store.getters.driveFiles
    },
    deviceFiles(): any {
      if (this.files.length) {
        return Array.from(this.files).map((file: any) => {
          return {
            file_name: file.name,
            source_name: file.name,
          }
        })
      } else {
        return []
      }
    },
    filesCount(): any {
      if (this.driveFiles.length) {
        const files = this.driveFiles.filter((file: any) => {
          const target = file.mimeType
          const query = 'folder'
          return !this.mixins.matchRegExp(target, query)
        })
        if (files.length) {
          return files.length
        } else {
          return 0
        }
      } else if (this.deviceFiles.length) {
        return this.deviceFiles.length
      } else {
        return null
      }
    }
  },
  watch: {
    //
  },
  async beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    countConfirm() {
      if (this.filesCount >= 30) {
        this.caution = true
      } else {
        this.add()
      }
    },
    reconfirm() {
      if (confirm('30件以上の動画を解析しますか？（かおたん担当の許可が必要です。）')) {
        this.add()
      }
    },
    async add() {
      if (this.driveFiles.length) {
        const inputs = JSON.parse(JSON.stringify(this.driveFiles))
        inputs.forEach((file: any) => {
          file.project_id = this.data.project_id
          file.folder_id = this.data.parent_folder_id
        })
        const res = await this.mixins.addSources(inputs)
        this.store.commit('setDriveFiles', [])
        if (res) {
          this.$emit('update')
        } else {
          console.log('error')
        }
      } else if (this.deviceFiles.length) {
        // // readerを利用する場合
        // const readFile = (file: any) => {
        //   return new Promise((resolve, reject) => {
        //     const reader = new FileReader()
        //     reader.onload = () => {
        //       resolve(reader.result)
        //     }
        //     reader.readAsDataURL(file)
        //   })
        // }
        // // 同期処理
        // readFile(file).then((res: any) => {
        //   console.log(res)
        // })
        const inputs = this.deviceFiles.map((file: any) => {
          return {
            filename: file.file_name,
            source_name: file.source_name,
          }
        })
        const res = await this.mixins.deviceupload(inputs, this.files, this.data)
        this.files = []
        if (res) {
          this.$emit('update')
        } else {
          console.log('error')
        }
      }
    },
    picker() {
      this.files = []
      this.mixins.pickerapi()
    },
    change(e: any) {
      const regexp = new RegExp(/video\/mp4|video\/quicktime|video\/avi|application\/octet-stream|video\/mp2t|text\/texmacs|video\/x-ms-wmv/, 'g')
      this.store.commit('setDriveFiles', [])
      this.files = Array.from(e.target.files).filter((file: any) => file.type.match(regexp))
    },
    close() {
      this.store.commit('setDriveFiles', [])
      this.files = []
      this.$emit('close')
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto
  &-footer
    grid-template auto \/ 1fr auto auto
    .memo
      // color crimson
      font-size .8rem
      line-height 1.5
  .caution
    width 800px
    height auto
    p
      line-height 2
    a
      display inline-block
      margin 0 .2em
      border-bottom 1px dotted currentColor
      line-height 1.3

.location
  display grid
  grid-template auto / auto 1fr
  align-items center
  grid-gap $gap * .5
  .path
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    .text
      ellipsis()

.ui
  margin-top $gap
  display grid
  grid-template 40px / auto
  grid-auto-flow column
  grid-gap $gap
  align-items center
  justify-content flex-start
  .button,
  .button label
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    cursor pointer
    .text
      ellipsis()
    #deviceFiles
      display none
    &.disabled
      opacity .3
      pointer-events none

.list
  height 250px
  border-top 1px solid #bababa
  border-bottom 1px solid #bababa
  background-color #F7F7F7
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em $gap
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3

    .name
      ellipsis()
  .empty
    padding $gap
    font-size .8rem
    line-height 1.5


//

</style>
