
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import DialogProjectShare from '@/components/dialog/ProjectShare.vue'
import DialogsSozai from '@/components/dialog/DialogsSozai.vue'
import DialogsFolder from '@/components/dialog/DialogsFolder.vue'
// ListItem
import ItemSozai from '@/components/item/Sozai.vue'

export default defineComponent({
  name: 'SozaiFinder',
  components: {
    'dialogs-sozai': DialogsSozai,
    'dialogs-folder': DialogsFolder,
    'dialog-share': DialogProjectShare,
    'item-sozai': ItemSozai,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    // const project = store.getters.project

    // リアクティブな変数を定義
    // let list: any = ref(null)
    // let context: any = ref(null)
    const queries = mixins.getQuery()
    // console.log('queries', queries)

    const project_id: any = route.params.project_id

    const currentProject = {
      project_id: parseInt(project_id),
    }

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      queries,
      currentProject,
      isPowerUser,
      isSuperUser,
      // list,
      // tree,
    }
  },
  data(): {
    project: any
    last_pwatch_updated_at_msec: any
    folders: any[]
    sources: any[]
    list: any[] | null
    tree: any[] | null
    context: any
    // add: any
    share: any
    // edit: any
    // del: any
    // move: any
    // copy: any
    dialog: any
    ui: any
    composing: boolean
    filtering: boolean
    isUpdate: boolean
    names: any
    castSuggests: any
  } {
    return {
      project: null,
      last_pwatch_updated_at_msec: null,
      folders: [],
      sources: [],
      list: null,
      tree: null,
      context: null,
      // add: null,
      share: null,
      // edit: null,
      // del: null,
      // move: null,
      // copy: null,
      dialog: {
        type: null,
        method: null,
        data: null,
      },
      ui: {
        folderView: true,
        viewType: 1,
        currentFolder: null,
        add: false,
        sort: {
          key: 'name', // ソートキー
          ascend: true, // true: 昇順, false: 降順
        },
        lastSelected: null,
        suggest: false,
      },
      composing: false,
      filtering: false,
      isUpdate: false,
      names: [],
      castSuggests: [],
    }
  },
  computed: {
    currentFolder_(): any {
      const currentFolderId = this.ui.currentFolder ? this.ui.currentFolder.folder_id : null
      const currentFolder = this.folders.find((folder: any) => folder.folder_id === currentFolderId)

      // 選択されているフォルダに属するfolderとsourceを抜粋
      let folders: any = this.folders.filter((folder: any) => folder.parent_folder_id === currentFolderId)
      let sources: any = this.sources_.filter((source: any) => source.folder_id === currentFolderId)

      // parent_tbl
      const parent_tbl = currentFolder ? Object.assign([], currentFolder.parent_tbl).reverse() : []

      // 所属フォルダにoffspringを生成
      let offspring: any = []
      const scan: any = (folder: any) => {
        this.sources_.forEach((source: any) => {
          if (source.folder_id === folder.folder_id) {
            offspring.push(source)
          }
        })
        const folders = this.folders.filter((kid: any) => kid.parent_folder_id === folder.folder_id)
        if (folders.length) {
          folders.forEach((kid: any) => {
            scan(kid)
          })
        }
      }
      folders.forEach((folder: any) => {
        offspring = []
        // console.log(folder)
        scan(folder)

        // ソート
        offspring.sort((a: any, b: any) => {
          switch (this.ui.sort.key) {
            case 'name': {
              const a_ = a['source_name'] || a['gdr_name']
              const b_ = b['source_name'] || b['gdr_name']
              if (a_ && b_) {
                return a_.localeCompare(b_, 'ja')
              } else {
                return -1
              }
            }
            case 'seconds': {
              return a[this.ui.sort.key] - b[this.ui.sort.key]
            }
            default: {
              if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
                return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
              } else {
                return -1
              }
            }
          }
        })
        // 昇順・降順
        if (!this.ui.sort.ascend) {
          offspring.reverse()
        }

        folder.offspring = offspring
      })

      // フォルダはここで絞り込み
      // offspringがいれば残す
      // そうでない場合、名称がひっかかれば残す

      // onSearchしたときにoffspringがいないフォルダも残ってる？
      folders = folders.filter((folder: any) => {
        if (folder.offspring.length) {
          // いずれにせよ子素材がいるときはtrue
        } else {
          if (this.filtering) {
            // 音声・人物絞り込み状態でoffspringがいないのはfalse
            return false
          } else {
            // 名称で絞り込まれているか
            if (folder.folder_name && this.sozaiQuery.name) {
              return this.mixins.matchRegExp(folder.folder_name, this.sozaiQuery.name)
            } else {
              return true
            }
          }
        }
        // 何かしら絞り込み条件が入力されている
        if (this.filtering) {
          //
          if (folder.offspring.length) {
            // 子素材ががいればtrue
            return true
          } else {
            if (folder.folder_name && this.sozaiQuery.name) {
              return this.mixins.matchRegExp(folder.folder_name, this.sozaiQuery.name)
            } else {
              return this.filtering
            }
          }
        } else {
          // 絞り込み条件なしの場合は全true
          return true
        }
      })

      // ソート
      // 素材
      // リストビューを考慮し、素材の並び替えはsources_で行う
      // sources.sort((a: any, b: any) => {
      //   switch (this.ui.sort.key) {
      //     case 'name': {
      //       const a_ = a['source_name'] || a['gdr_name']
      //       const b_ = b['source_name'] || b['gdr_name']
      //       return a_.localeCompare(b_, 'ja')
      //     }
      //     case 'seconds': {
      //       return a[this.ui.sort.key] - b[this.ui.sort.key]
      //     }
      //     default: {
      //       return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
      //     }
      //   }
      // })
      // フォルダ
      folders.sort((a: any, b: any) => {
        switch (this.ui.sort.key) {
          case 'name': {
            return a['folder_name'].localeCompare(b['folder_name'], 'ja')
          }
          default: {
            return a['folder_name'].localeCompare(b['folder_name'], 'ja')
          }
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        // sources.reverse()
        if (this.ui.sort.key === 'name') {
          folders.reverse()
        }
      }
      return {
        folder_name: 'root',
        parent_folder_id: null,
        parent_tbl: parent_tbl,
        folders: folders,
        sources: sources,
      }
    },
    sources_(): any {
      let sources: any = JSON.parse(JSON.stringify(this.sources))
      if (sources) {
        sources = sources.filter((sozai: any) => {
          if (sozai.folder_id !== null) {
            // 先祖フォルダをもっている場合はそちらでも挑戦
            if (this.mixins.matchRegExp(sozai.pathString, this.sozaiQuery.name)) {
              return true
            }
          }
          if (sozai.source_name) {
            return this.mixins.matchRegExp(sozai.source_name, this.sozaiQuery.name)
          } else if (sozai.gdr_name) {
            return this.mixins.matchRegExp(sozai.gdr_name, this.sozaiQuery.name)
          } else {
            return false
          }
        })
      }
      // ソート
      sources.sort((a: any, b: any) => {
        switch (this.ui.sort.key) {
          case 'name': {
            const a_ = a['source_name'] || a['gdr_name']
            const b_ = b['source_name'] || b['gdr_name']
            if (a_ && b_) {
              return a_.localeCompare(b_, 'ja')
            } else {
              return -1
            }
          }
          case 'seconds': {
            return a[this.ui.sort.key] - b[this.ui.sort.key]
          }
          case 'start_time': {
            const a_ = this.mixins.getFormatedTimeCode(a.start_time)
            const b_ = this.mixins.getFormatedTimeCode(b.start_time)
            if (a_ && b_) {
              return a_.localeCompare(b_, 'ja')
            } else {
              return -1
            }
          }
          default: {
            if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
              return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
            } else {
              return -1
            }
          }
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        sources.reverse()
      }

      return sources
    },
    sort: {
      get(): any {
        return this.store.getters.sort
      },
      set(query): any {
        this.store.commit('setSort', query)
      },
    },
    sozaiQuery: {
      get(): any {
        return this.store.getters.filtersSozaiQuery
      },
      set(query): any {
        this.store.commit('setFiltersSozaiQuery', query)
      },
    },
    selects(): any {
      const offsprings = Object.assign([], this.store.getters.selectedFolders).map((folder: any) => { return folder.offspring })
      let ss = Object.assign([], this.store.getters.selectedSources) // これがないとsourceがアクティブにならない
      // const selects = this.sources_.filter((source: any) => source.select || sf.some((folder_id: number) => folder_id === source.folder_id))
      offsprings.forEach((offspring: any) => {
        ss = ss.concat(offspring)
      })
      // const selects = ss.concat()
      // console.log(ss)
      return ss
    },
    selectedFolders: {
      get(): any {
        return this.store.getters.selectedFolders
      },
      set(query): any {
        this.store.commit('setSelectedFolders', query)
      },
    },
    selectedSources: {
      get(): any {
        return this.store.getters.selectedSources
      },
      set(query): any {
        this.store.commit('setSelectedSources', query)
      },
    },
  },
  watch: {
    'ui.add': function () {
      if (this.ui.add) {
        window.addEventListener('click', this.closeAddMenu)
      } else {
        window.removeEventListener('click', this.closeAddMenu)
      }
    },
    'ui.currentFolder': function () {
      let path = location.pathname
      if (this.ui.currentFolder) {
        path += `?folderId=${this.ui.currentFolder.folder_id}`
      }
      // queryを書き換え
      history.replaceState(null, '', path)
      // storeにも入れる
      this.store.commit('setFolder', this.ui.currentFolder)
    },
    'sozaiQuery.cast': function (val: any) {
      this.refreshCastSuggest(val)
    },
    sources() {
      this.setPathString()
    },
  },
  async beforeMount() {
    if (!this.currentProject) {
      if (this.queries.projectId) {
        // currentProjectをのっとり
        this.currentProject = {
          project_id: parseInt(this.queries.projectId),
        }
      } else {
        this.router.push('/')
        return
      }
    }
    // currentProjectがいるはず
    await this.refresh()
    if (this.sozaiQuery.cast || this.sozaiQuery.speech) {
      await this.onFilter()
    }
  },
  beforeUnmount() {
    this.clearSelected()
  },
  methods: {
    // コンテキストメニューを開く
    openContextMenu(e: any, item: any, index: any, folder: any, offspring: any) {
      // 自身の選択状態で判定
      if (!item.select) {
        // 選択状態を解除
        this.sources_.forEach((item: any, i: any) => {
          item.select = false
        })
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = true
      this.selectedSources = this.sources_.filter((item: any) => item.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)

      this.context = {
        type: item.source_id ? 'source' : 'folder',
        item: item,
        position: { x: e.clientX, y: e.clientY }
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return false }
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      // e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return true }
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      const wb = window.innerHeight
      // console.log(x, y)
      if (this.selects.length === 1) {
        return { left: `${x}px`, top: `${wb - y > 280 ? y - 10 : wb - 290}px` }
      } else {
        return { left: `${x}px`, top: `${wb - y > 230 ? y - 10 : wb - 240}px` }
      }
    },
    // 素材の追加
    openAdd(type: string) {
      this.dialog = {
        type: type,
        method: 'add',
        data: {
          project_id: this.project.project_id,
          parent_folder_id: this.ui.currentFolder ? this.ui.currentFolder.folder_id : null,
        },
      }
      // this.add = {
      //   path: [this.project.name]
      // }
    },
    // 共有設定
    openShare() {
      this.share = this.project
    },
    // 素材の編集
    openEdit() {
      this.dialog = {
        type: this.context.type,
        method: 'edit',
        data: this.context.item,
      }
      // this.edit = this.context.item
    },
    // 素材の移動
    openMove() {
      this.dialog = {
        type: this.context.type,
        method: 'move',
        data: this.context.item,
      }
      // this.move = this.context.item
    },
    // 素材のコピー
    openCopy() {
      this.dialog = {
        type: this.context.type,
        method: 'copy',
        data: this.context.item,
      }
      // this.copy = this.context.item
    },
    // 素材の削除
    openDelete() {
      this.dialog = {
        type: this.context.type,
        method: 'delete',
        data: this.context.item,
      }
    },
    async openLog(item: any) {
      console.log(item)
      const inputs = [item]
      const log = await this.mixins.getsourcelog(inputs)
      alert(log?.outputs[0].text)
    },
    closeDialog() {
      this.share = null
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
    },
    selectSource(e:any, item: any, index: any, folder: any, offspring: any) {
      // forで回す配列を指定する
      // フォルダビューONの場合：offspring配列 or currentFolderのsources
      // フォルダビューOFFの場合: souces_
      let arr: any
      if (folder) {
        if (offspring) {
          arr = folder.offspring
        } else {
          arr = folder.sources
        }
      } else {
        arr = this.sources_
      }
      // 一つ前に選択された素材との関係でリセットする
      if (folder?.folder_id || this.ui.lastSelected?.folder_id) {
        if (this.ui.lastSelected?.folder_id !== folder?.folder_id) {
          // 前回はoffspringを指定、かつ今回別レイヤーの素材を選択
          // 選択状態を解除
          this.sources_.forEach((item: any, i: any) => {
            item.select = false
          })
          this.ui.lastSelected = null
        }
      }

      // 変数
      let from: any = null
      let count: any = null
      // シフトキーが押されている
      if (e.shiftKey) {
        // 選択状態を解除
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
        // 前クリックがある、選択されているものがある
        if (this.ui.lastSelected !== null && this.selectedSources.length) {
          const diff = index - this.ui.lastSelected.index
          if (Math.sign(diff) > 0) {
            from = this.ui.lastSelected.index
            count = diff
          } else {
            from = index
            count = diff * -1
          }
          for (let i = from; i <= from + count; i++) {
            arr[i].select = true
          }
        } else {
          item.select = !item.select
        }
      } else {
        const select_ = item.select
        // メタキーが押されていない
        if (!e.ctrlKey && !e.metaKey) {
          // 選択状態を解除
          this.sources_.forEach((item: any, i: any) => {
            item.select = false
          })
          this.folders.forEach((item: any, i: any) => {
            item.select = false
          })
        }
        if ((!e.ctrlKey && !e.metaKey) && this.selectedSources.length > 1 && select_) {
          item.select = true
        } else {
          item.select = !select_
        }
      }
      this.ui.lastSelected = {index: index, folder_id: folder?.folder_id || null}
      this.selectedSources = this.sources_.filter((item: any) => item.select)
      if (!this.selectedSources.length) {
        this.ui.lastSelected = null
      }
      document.getSelection()?.empty()

      this.closeContextMenu(null)
      // item.select = !item.select // これをおいただけでは非リアクティブ？
      // this.selectedSources = this.sources_.filter((source: any) => source.select)
    },
    selectFolder(e: any, item: any) {
      const select_ = item.select
      if (!e.ctrlKey && !e.metaKey) {
        // 選択状態を解除
        this.sources_.forEach((source: any) => {
          source.select = false
        })
        this.folders.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = !select_
      item.offspring.forEach((source: any) => {
        source.select = false
      })
      this.selectedSources = this.sources.filter((item: any) => item.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)
      // console.log(this.selectedFolders)
      document.getSelection()?.empty()
    },
    clearSelected() {
      this.sources.forEach((source: any) => {
        source.select = false
      })
      this.folders.forEach((folder: any) => {
        folder.select = false
      })
      this.selectedSources = this.sources.filter((source: any) => source.select)
      this.selectedFolders = this.folders.filter((folder: any) => folder.select)
      this.ui.lastSelected = null
    },
    openFolder(folder: any) {
      // フォルダ移動する際に、選択状態をクリア
      this.clearSelected()
      this.ui.currentFolder = folder
    },
    clearPath() {
      this.ui.currentFolder = null
      this.queries = {}
    },
    closeAddMenu() {
      this.ui.add = false
    },
    // キーワードから探す
    async onFilter() {
      if (this.composing) { return }

      // 人物サジェスト選択
      // input中に上下キーでサジェストを選択した場合のルート
      const selected = this.castSuggests.find((suggest: any) => suggest.select)
      if (selected) {
        // 人物選択のenter
        this.clickSuggest(selected)
        return
      }
      const sources = await this.mixins.searchsource(this.sozaiQuery, this.project.project_id)
      this.sources = sources
      this.filtering = true
      this.ui.suggest = false
    },
    resetSource() {
      this.clearSelected()
      this.sources = Object.assign([], this.project.sources)
      this.filtering = false
    },
    updateQuery(e: any) {
      if (!e.target.value) {
        this.resetSource()
      }
    },
    // 人物サジェストを更新
    refreshCastSuggest(queryString: any) {
      let suggests: any = null
      // const queryString = val
      // 前後の空文字をトリム
      const trim = queryString.replace(/^[\u{20}\u{3000}]+/gu, '')
      if (!trim) {
        this.resetSource()
        this.castSuggests = this.names.map((text: any) => {
          return {
            text: text,
            select: false,
          }
        })
      } else {
        // 文中のスペースをTABに置き換え
        // TAB区切りで配列化
        const queries = trim.replace(/[\u{20}\u{3000}]+/gu, '\t').split('\t')
        // 最後のワードに的をしぼる
        const lastQuery = queries.slice(-1)[0]
        if (lastQuery) {
          // namesから該当するものをフィルタ
          suggests = this.names.filter((target: any) => {
            const query = `^(?=.*${lastQuery}).*$`
            const reg = new RegExp(query, 'gi')
            return target.match(reg)?.length ? true : false
          })
          this.castSuggests = suggests.map((text: any) => {
            return {
              text: text,
              select: false,
            }
          })
        } else {
          this.castSuggests = this.names.map((text: any) => {
            return {
              text: text,
              select: false,
            }
          })
        }
      }
    },
    // サジェスト選択
    selectSuggest(diff: any) {
      if (this.composing || !this.castSuggests.length) { return }
      let selected = this.castSuggests.findIndex((suggest: any) => suggest.select)
      if (selected !== -1) {
        // 選択中のsuggestがある
        // 選択中のものをfalseにする
        this.castSuggests[selected].select = false
        // indexを更新
        selected += diff
        // 超過判定
        if (selected < 0) {
          selected = 0
        } else if (selected > this.castSuggests.length - 1) {
          selected = this.castSuggests.length - 1
        }
        this.castSuggests[selected].select = true
      } else {
        // 選択中のsuggestがない
        // => 先頭を選択中に
        selected = 0
        this.castSuggests[0].select = true
      }
    },
    clickSuggest(item: any) {
      const trim = this.sozaiQuery.cast.replace(/^[\u{20}\u{3000}]+/gu, '')
      let queries = trim.replace(/[\u{20}\u{3000}]+/gu, '\v').split('\v') // 垂直タブに置換してsplit
      // 最後の子を置換して連結したものをcastに代入
      queries.pop() // splitで''が入るのでpop
      // 人物名のスペースを置換（NeoEva著名人対策）
      queries.push(item.text.replace(/[\u{20}\u{3000}]/gu, '\t')) // 著名人名のスペースは水平タブに置換
      this.sozaiQuery.cast = queries.join(' ')
      // サジェストリストを非表示に
      // this.ui.suggest = false
      // searchまでやっちゃう
      this.castSuggests.forEach((suggest: any) => suggest.select = false)
      this.onFilter()
    },
    clearSelectSuggest() {
      this.castSuggests.forEach((suggest: any) => {
        suggest.select = false
      })
      this.ui.suggest = false
    },
    // シーン検索へ
    onSearch(e: any) {
      const alert: any = []
      const selects = this.selects
        .map((source: any) => {
          const queryString = 'アップロード中|解析中'
          // create_ginfos以降は許可
          if (source.str_status === '完了') {
             return {
              source_name: source.source_name,
              gdr_name: source.gdr_name,
              source_id: source.source_id,
            }
          } else if (!this.mixins.matchRegExp(source.str_status, queryString)) {
            alert.push(source.source_name || source.gdr_name)
            return {
              source_name: source.source_name,
              gdr_name: source.gdr_name,
              source_id: source.source_id,
            }
          } else {
            alert.push(source.source_name || source.gdr_name)
            return false
          }
        })
        .filter((source: any) => source)
      if (alert.length) {
        let alertString: any = '処理中の動画が含まれています\n'
        alertString += alert.join('\n')
        if (!confirm(alertString)) {
          return
        }
      }
      // console.log(selects)
      if (selects.length > 2) {
        if (!confirm('解析結果の取得に時間がかかる可能性があります')) {
          return
        }
      } else if (selects.length === 0) {
        return
      }
      const sourceId = selects.map((source: any) => {
        return source.source_id
      })
      // this.store.commit('setSearch', selects)
      let query = `?sourceId=${encodeURIComponent(sourceId.join(','))}`
      if (this.sozaiQuery.speech) query += `&speech=${encodeURIComponent(this.sozaiQuery.speech)}`
      if (this.sozaiQuery.cast) query += `&cast=${encodeURIComponent(this.sozaiQuery.cast)}`
      // this.router.push(`./search/${query}`)
      if (e.altKey) {
        this.router.push(`./search/${query}`)
      } else {
        window.open(`./search/${query}`, '_blank')
      }
    },
    // 単体解析結果へ
    onResult(e: any, item: any) {
      // const queryString = 'search_1n|get_wav|put_wav|speech_to_text|put_speech_to_text|get_conv_video|put_nod_video|get_nod_json|put_nod_json|complete'
      const queryString = 'アップロード中|解析中'
      // create_ginfos以降は許可
      if (this.mixins.matchRegExp(item.str_status, queryString)) {
        alert('処理中の動画です')
        return
      }
      this.store.commit('setResult', item)
      let query = `?sourceId=${encodeURIComponent(item.source_id)}`
      if (this.sozaiQuery.speech) query += `&speech=${encodeURIComponent(this.sozaiQuery.speech)}`
      if (this.sozaiQuery.cast) query += `&cast=${encodeURIComponent(this.sozaiQuery.cast)}`
      // this.router.push(`./result/${query}`)
      if (e.altKey) {
        this.router.push(`./result/${query}`)
      } else {
        window.open(`./result/${query}`, '_blank')
      }
    },
    // 再解析
    async onResearch() {
      const res = await this.mixins.research(this.selects)
      if (res) {
        this.refresh()
      } else {
        console.log('エラー')
      }
    },
    // ソート指定
    setSort(key: string) {
      if (key === this.ui.sort.key) {
        this.ui.sort.ascend = !this.ui.sort.ascend
      } else {
        this.ui.sort.key = key
        this.ui.sort.ascend = true
      }
      this.sort = this.ui.sort
    },
    // 検索用のパスを生成
    setPathString() {
      this.sources.forEach((source: any) => {
        // 検索用にここでpathを生成
        const parent_folder = this.folders.find((folder: any) => folder.folder_id === source.folder_id)
        source.parent_tbl = parent_folder ? Object.assign([], parent_folder.parent_tbl) : []
        const pathString = source.parent_tbl.map((path: any) => { return path.folder_name }).reverse().join(' > ')
        source.pathString = pathString ? pathString : '/'
      })
    },
    async refresh() {
      // プロジェクト詳細を取得しviewで必要な値をセット
      this.project = await this.mixins.getProject(this.currentProject)
      this.last_pwatch_updated_at_msec = new Date(this.project.last_pwatch_updated_at).getTime() || 0

      // null check
      if (!this.project) {
        console.log('projectが取得できない')
        this.$router.push('/')
      } else {
        document.title = `${this.project.project_name}｜KAOTAN`

        // 改めてセットする
        this.store.commit('setProject', this.project)

        this.store.commit('setFolders', this.project.folders)

        // 部分更新できるように分離
        this.folders = Object.assign([], this.project.folders)
        this.sources = Object.assign([], this.project.sources)

        // 初期値
        // query指定されたfolderが存在してたら入れる
        let currentFolder: any = null
        if (this.queries.folderId) {
          currentFolder = this.project.folders.find((folder: any) => folder.folder_id === parseInt(this.queries.folderId))
        } else if (this.store.getters.folder) {
          currentFolder = this.store.getters.folder
        }
        this.ui.currentFolder = currentFolder

        // 人物サジェストリストを作成
        const names: any = []
        this.sources.forEach((source: any) => {
          if (source.names) {
            source.names.split(',').forEach((name: any) => {
              if (names.every((n: any) => n !== name)) {
                names.push(name)
              }
            })
          }
        })
        this.names = names
        this.refreshCastSuggest(this.sozaiQuery.cast)

        // ダイアログもリセット
        this.closeDialog()
      }
    },
  },
})
