<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">新規フォルダを作成</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <!-- 追加する場所 -->
        <div class="location">
          <div class="label">作成する場所：</div>
          <div class="path" v-if="!current.folder"><span class="icon"><i class="material-icons">movie</i></span><span class="text">{{ current.project.project_name }}</span></div>
          <div class="path" v-else><span class="icon"><i class="material-icons">folder</i></span><span class="text">{{ current.folder.folder_name }}</span></div>
        </div>
        <div class="input text"><input type="text" v-model="editable.folder_name" placeholder="フォルダ名を入力してください"></div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="add" :class="{disabled: !editable.folder_name}">作成</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'FolderAdd',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup() {
    const store = useStore()
    const mixins = new Mixins()
    return {
      store,
      mixins,
    }
  },
  data(): {
    editable: any
    current: any
  } {
    return {
      editable: null,
      current: {
        project: null,
        folder: null,
      },
    }
  },
  computed: {
    validation() {
      let error = ''
      if (!this.editable.folder_name) {
        error += 'フォルダ名が未入力です'
      }
      return error
    },
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = Object.assign({}, this.data)
    this.editable.folder_name = ''
    this.current.project = this.store.getters.project
    this.current.folder = this.store.getters.folder
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async add() {
      if (this.validation) {
        alert(this.validation)
        return
      }
      const res = await this.mixins.createfolder(this.editable)
      if (res) {
        this.$emit('update')
      }
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

.location
  display grid
  grid-template auto / auto 1fr
  align-items center
  grid-gap $gap * .5
  .path
    display grid
    grid-template auto / auto 1fr
    grid-gap .5em
    align-items center
    .text
      ellipsis()

.input
  margin-top $gap
  &.text
    background-color #F4F4F4
    border-bottom 1px solid #BABABA
    padding 0 .5em
    input
      display block
      width 100%
      line-height 50px
      &::placeholder
        color #aaa

//


</style>
